import React, { useContext } from "react"
import { LanguageContext } from "../../context"
import Banner from "../banner"
import Button2 from "../button-generic"
import { Link, navigate } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import Utils from "../../utils"


const AboutUsPage = (props) => {

  const {
    post,
    bannerHeight="512px",
    aboutus
  } = props
  const { t } = useContext(LanguageContext)
  const breakpoints = useBreakpoint()

  return(
    <div className="page-about-us">
    <Banner
      post={post}
      height={bannerHeight}
    />
    <section className="section-about-us p-t-80 p-b-88 bordeaux-section medium-p-t-0 small-p-t-0 small-p-l-r-16 small-p-b-40 medium-p-b-0">

        <div className={`${breakpoints.md ? 'container-unida' : 'container p-0 '} tovisitus`}>
        <h2 className="H2-Medium-C---bordeaux p-b-80 medium-m-b-74 small-m-b-16 medium-p-b-0 small-p-b-0 small-p-t-25 medium-p-t-55">
          {t("Sobre Nosaltres")}
        </h2>

        <div className={`about-us-content ${breakpoints.md ? '' : 'row'}`}>
        {breakpoints.md

        ?
        <>
        {aboutus && aboutus?.edges?.map(({node}, index) => {
          return(
              <SectionAboutUsMobile
                post={node}
                imagePos='left'
                pos={1}
                t={t}
                className={`col-12 col-md-6 p-l-9 left`}
              />
          )
        })}
        </>
        :
        <>
        {aboutus && aboutus?.edges?.map(({node}, index) => {
          return(
              <SectionAboutUs
                post={node}
                imagePos={index > 0 && index % 2 !== 0 ? 'right' : 'left'}
                pos={1}
                t={t}
                className={`col-12 col-md-6 p-l-9 ${index > 0 && index % 2 !== 0 ? 'p-t-276 right small-m-b-24 small-p-t-24' : 'left'}`}
              />
          )
        })}
        </>

        }

        </div>

      </div>

    </section>

    </div>
  )
}



const SectionAboutUs = (props) => {
  const {post, className} = props
  return(
    <div className={className}>
      <div className="background-beige m-b-42 small-m-b-0">
      <img
        src={post?.relationships?.field_image?.image_style_uri?._653_432}
        alt={post?.title}
        className="m-r--24"
      />
       <Link
        className="r-more bordeaux"
        to={Utils.removeMainLanguage(post?.field_cta?.url)}
        >
        {post?.field_cta?.title}
        </Link>
      </div>
      <div className="about-us-desc ">
        <h3 className="H3-Bold-L---bordeaux">{post?.title}</h3>
        <div className="H5-Light-L---black" dangerouslySetInnerHTML={{__html: post?.body?.processed}} />
      </div>
      </div>
    )
}

const SectionAboutUsMobile = (props) => {
  const {post, className} = props
  return(
    <div className="row small-p-b-24">
      <div className="background-beige m-b-42 small-m-b-0 col-md-6 small-p-0 small-p-b-14 medium-m-b-82">
      <img
        src={post?.relationships?.field_image?.image_style_uri?._653_432}
        alt={post?.title}
        className="m-r--24"
      />
      <Link
       title={post?.field_cta?.title}
       className="r-more bordeaux"
       >
       {post?.field_cta?.title}
       </Link>
      </div>
      <div className="about-us-desc col-md-6">
        <h3 className="H3-Bold-L---bordeaux">{post?.title}</h3>
        <div className="H5-Light-L---black" dangerouslySetInnerHTML={{__html: post?.body?.processed}} />
      </div>
      </div>
    )
}


 export default AboutUsPage
