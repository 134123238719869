import React from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {homePagePaths, translate } from '../translate'
import Header from "../components/header"
import Footer from "../components/footer"
import Title from "../components/title"
import AboutUsPage from "../components/pages/about-us-page"

const PageAboutUs = ({ data, location, pageContext }) => {

  const post = data?.nodePage
  const english = data?.english
  const spanish = data?.spanish
  //const catalan = data?.catalan
  const aboutus = data?.aboutus

  const translationPaths = {
   en: english?.path?.alias,
   es: spanish?.path?.alias,
 }

  return (
    <Layout
      location={location}
      langcode={post.langcode}
      translationPaths={translationPaths}
    >
    <Seo
        title={post.title}
        lang={post.langcode}
        description={post.body.summary}
        image={post?.relationships?.field_image?.image_style_uri?._653_432}
      />
      <Header
        lang={pageContext?.langcode}
        location={location}
       />
        <AboutUsPage
          post={post}
          lang={post.langcode}
          bannerHeight="512px"
          aboutus={aboutus}
         />
      <Footer
        lang={pageContext?.langcode}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
query PageAboutUs ($drupal_internal__nid: Int, $langcode: String!) {
    nodePage (
        drupal_internal__nid: {eq: $drupal_internal__nid},
        langcode:{eq: $langcode}
        ){
          langcode
          drupal_internal__nid
          id
          title
          drupal_id
          body {
            processed
            value
            summary
          }
          created
          relationships {
            field_image {
              image_style_uri {
                _294x192
                _541x359
                _653_432
                large
                medium
                thumbnail
                wide
              }
            }
          }
        }
       aboutus: allNodeAboutUs (
         filter: {langcode: {eq: $langcode}}
         sort: {fields: field_weight, order: ASC}
       )  {
            edges{
              node{
                title
                body {
                  processed
                }
                relationships {
                  field_image {
                    image_style_uri {
                      _294x192
                      _653_432
                      _541x359
                      large
                      medium
                      thumbnail
                      wide
                    }
                  }
                }
                field_cta {
                  title
                  uri
                  url
                }
                field_weight
              }
            }
          }
}
`

export default PageAboutUs
